<template>
  <div :class="['heading']">
    <h1
      v-if="data.text"
      class="typo--h1"
    >
      {{ data.text }}
    </h1>
    <div
      v-if="data.links && data.links.length > 0"
      class="links typo--link"
    >
      <Cta
        v-for="item in data.links"
        :key="item.link.url"
        :data="{
          url: item.link.url,
          target: item.link.target,
          title: item.link.title,
        }"
        theme="link"
      />
    </div>
  </div>
</template>

<script>
import Cta from '@/components/typo/cta.vue';

export default {
  name: 'Heading',
  components: {
    Cta,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
  .heading {
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    column-gap: var(--column-gap-s);
    row-gap: var(--row-gap-xs);
    justify-items: start;

    @include mq(m) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    @include mq(l) {
      row-gap: 0.8vw;
    }

    > * {
      grid-column: 1 / -1;
    }

    .links {
      grid-column-start: 2;

      > * {
        max-width: max-content;
      }
    }
  }
</style>
