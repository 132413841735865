<template>
  <div class="not-found">
    <h1 class="typo--h1">
      Error 404.
      <br>
      Page not found.
    </h1>
  </div>
</template>

<script>

export default {
  name: 'NotFound',
};
</script>

<style lang="scss">
  .not-found {
    padding: var(--spacer-s);
    margin-top: var(--spacer-page);
  }
</style>
