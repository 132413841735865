var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header"},[_c('nav',{class:{'overlay-page': _vm.$route?.meta?.single}},[_vm._l((_vm.menu.items),function(item){return _c('Cta',{key:item.id,attrs:{"data":{
        title: item.content,
        url: item.url,
      }}})}),(_vm.$route.name === 'Home')?_c('transition',{attrs:{"mode":"out-in","css":false},on:{"before-enter":_vm.beforeEnterNewsletter,"enter":_vm.enterNewsletter,"leave":_vm.leave}},[(!_vm.$mq.isMobile && _vm.$route.name === 'Home')?_c('Newsletter'):_vm._e()],1):(_vm.$route?.meta?.single)?_c('transition',{attrs:{"mode":"out-in","css":false},on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter}},[(_vm.$route?.meta?.single)?_c('Cta',{staticClass:"close-button",attrs:{"data":{
          title: 'X',
          url: '/',
        }}}):_vm._e()],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }