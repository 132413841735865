var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns",style:({
    gridTemplateColumns: !_vm.$mq.isMobile
      ? `repeat(${_vm.data.length}, minmax(0, 1fr))`
      : '1fr',
  })},_vm._l((_vm.columns),function(blocks,columnIndex){return _c('div',{key:columnIndex},_vm._l((blocks),function(block){return _c(block.component,{key:block.attrs.id,tag:"component",attrs:{"data":block.blockName === 'core/columns' ? block.innerBlocks :
        block.attrs.data
          ? { ...block.attrs.data, ratio: true }
          : block.innerHTML}})}),1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }