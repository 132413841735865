<template>
  <div :class="['video', data.align === 'center' ? 'video--center' : false]">
    <div
      ref="width"
      class="video-width"
      :style="{ maxWidth: maxWidth }"
    >
      <div
        class="video-container"
        :style="{ paddingBottom: ratio ? `${ratio * 100}%` : false }"
      >
        <video
          v-if="gallery"
          ref="htmlplayer"
          autoplay
          muted
          loop
          playsinline
        >
          <source
            :src="data.url"
            :type="data.mime_type"
          >
        </video>

        <div
          v-else-if="data.video"
          ref="player"
          v-html="data.video"
        />
      </div>
    </div>
    <div
      v-if="data.caption"
      class="caption typo--caption"
      v-html="data.caption"
    />
  </div>
</template>

<script>
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

export default {
  name: 'Video',
  props: {
    data: {
      type: Object,
      required: true,
    },
    gallery: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['set-previous-width'],
  data() {
    return {
      plyr: null,
      playing: false,
      loading: false,
      ratio: false,
      maxHeight: 600,
    };
  },
  computed: {
    maxWidth() {
      let width = '100%';
      let factor = 1;

      if (this.ratio) {
        if (this.gallery) {
          factor = this.data.width / this.data.height;
          width = `${this.maxHeight * factor}px`;
        } else {
          factor = 1 / this.ratio;
          width = `${this.maxHeight * factor}px`;
        }
      }

      return width;
    },
  },
  mounted() {
    this.getRatio();
    if (this.gallery) {
      this.plyr = new Plyr(this.$refs.htmlplayer, {
        autoplay: true,
        controls: [],
        fullscreen: {
          enabled: false,
        },
        muted: true,
      });
      this.$nextTick(() => {
        this.setPreviousWidth();
      });
      this.$bus.$on('windowResized', this.setPreviousWidth);
    } else if (this.data.video) {
      this.plyr = new Plyr(this.$refs.player, {
        autoplay: false,
        controls: ['play', 'progress', 'mute'],
        fullscreen: {
          enabled: false,
        },
        muted: true,
        vimeo: {
          controls: false,
        },
      });
    }
  },
  beforeDestroy() {
    this.$bus.$off('windowResized', this.setPreviousWidth);
  },
  methods: {
    getRatio() {
      let width = 0;
      let height = 0;

      if (this.gallery) {
        width = this.data.width;
        height = this.data.height;
      } else {
        const iframe = document.createElement('div');
        iframe.innerHTML = this.data.video;
        width = iframe.firstChild.getAttribute('width');
        height = iframe.firstChild.getAttribute('height');
      }

      this.ratio = height / width;
    },
    setPreviousWidth() {
      const width = this.$refs.width.clientWidth;
      this.$emit('set-previous-width', width);
    },
  },
};
</script>

<style lang="scss">
  .video {
    display: grid;
    grid-template-columns: repeat(var(--single-columns), minmax(0, 1fr));
    column-gap: var(--spacer-s);

    video {
      position: absolute;
    }

    .video-width {
      grid-column: 1 / -1;
    }

    .video-container {
      position: relative;
    }

    &--center {
      .video-width {
        @include mq(m) {
          grid-column-start: 4;
        }
      }
    }

    .plyr {
      --plyr-color-main: transparent;
      --plyr-range-track-height: var(--line);
      --plyr-range-thumb-height: 0px;
      --plyr-range-thumb-shadow: none;
      --plyr-control-icon-size: 16px;
      --plyr-control-spacing: var(--spacer-s);
      --plyr-control-padding: var(--spacer-s);
      --plyr-video-controls-background: transparent;
      --plyr-range-fill-background: var(--white);

      cursor: pointer;
      height: 100%;
      width: 100%;
      position: absolute;

      &__controls {
        // mix-blend-mode: difference;
      }

      &__tooltip {
        display: none;
      }

      &__volume {
        width: initial;
        max-width: initial;
        min-width: initial;
      }
    }

    .caption {
      margin-top: var(--spacer-xs);
      grid-column: 2 / -1;
    }
  }
</style>
