<template>
  <div id="app">
    <!-- :style="{ marginRight: `${scrollbarWidth}px` }" -->
    <AppHeader />
    <div class="page-container">
      <router-view :key="filteredPath" />
      <AppFooter
        v-if="
          $route.name === 'Home' || $route.name === '404' || $route.meta.single
        "
      />
      <Newsletter
        v-if="$route.name === 'Home' || $route.meta.single"
        theme="mobile"
      />
    </div>
    <Loader />
    <Cookie v-if="options && options.cookies" />
    <transition
      appear
      mode="out-in"
      :css="false"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <router-view
        :key="$route.path"
        name="single"
        @scrolled="scrolled"
      />
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import debounce from 'lodash.debounce';
import { animate } from 'motion';
import medusa from '@/assets/js/observer';
import lazyload from '@/mixins/lazyload';

import AppHeader from '@/components/ui/header';
import AppFooter from '@/components/ui/footer';
import Loader from '@/components/ui/loader';
import Cookie from '@/components/ui/cookie';
import Newsletter from '@/components/blocks/newsletter.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    Cookie,
    Loader,
    Newsletter,
  },
  mixins: [lazyload],
  data() {
    return {
      singleWasScrolled: false,
      toSingle: false,
      fromSingle: false,
    };
  },
  computed: {
    ...mapGetters(['currentPost', 'options', 'scrollbarWidth']),
    filteredPath() {
      const { from } = this.$store.state.route;

      if (this.$route.meta.single && from && from.name === 'Home') {
        return from.path;
      }
      return this.$route.path;
    },
  },
  created() {
    medusa.init();
  },
  mounted() {
    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    window.addEventListener(
      'resize',
      debounce(() => {
        this.$bus.$emit('windowResized');
        Vue.set(Vue.prototype, '$mq', this.$mq.setMq());
        this.$mq.vh();
      }, 0),
    );
    this.CREATE_SCROLLBAR();
    this.SET_SCROLLBAR_WIDTH();

    this.$bus.$on('windowResized', this.SET_SCROLLBAR_WIDTH);
  },
  methods: {
    ...mapMutations(['CREATE_SCROLLBAR', 'SET_SCROLLBAR_WIDTH']),
    scrolled() {
      this.singleWasScrolled = true;
    },
    beforeEnter(el) {
      if (!(this.toSingle && this.fromSingle)) {
        el.style.transform = 'translateY(calc(var(--vh) * 100))';
      } else if ((this.toSingle && this.fromSingle)) {
        window.scrollTo(0, 0);
      }
    },
    enter(el, done) {
      if (!(this.toSingle && this.fromSingle)) {
        this.$store.commit('SET_TRANSITION', false);
        animate(el, {
          transform: 'translateY(0)',
        }).finished.then(() => {
          done();
        });
      } else {
        this.$store.commit('SET_TRANSITION', false);
        done();
      }
    },
    beforeLeave(el) {
      if (!this.singleWasScrolled) {
        el.style.height = 'calc(var(--vh) * 100)';
        el.style.overflow = 'hidden';
      }
    },
    leave(el, done) {
      if (!(this.toSingle && this.fromSingle)) {
        if (!this.singleWasScrolled) {
          animate(el, {
            transform: 'translateY(calc(var(--vh) * -100))',
          }).finished.then(() => {
            if (!this.toSingle) {
              this.$store.dispatch('unlockView', {
                selector1: '.page-container',
                selector2: '.single',
              });
            }
            done();
            this.$store.commit('SET_TRANSITION', true);
          });
        } else {
          if (!this.toSingle) {
            this.$store.dispatch('unlockView', {
              selector1: '.page-container',
              selector2: '.single',
            });
          }
          this.singleWasScrolled = false;
          done();
          this.$store.commit('SET_TRANSITION', true);
        }
      } else {
        this.singleWasScrolled = false;
        done();
        this.$store.commit('SET_TRANSITION', true);
      }
    },

  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        if (to?.meta?.single && from?.meta?.single) {
          this.toSingle = true;
          this.fromSingle = true;
        } else if (to?.meta?.single && !from?.meta?.single) {
          this.toSingle = true;
          this.fromSingle = false;
        } else if (!to?.meta?.single && from?.meta?.single) {
          this.toSingle = false;
          this.fromSingle = true;
        } else {
          this.toSingle = false;
          this.fromSingle = false;
        }
      }
    },
  },
  beforeDestroy() {
    this.$bus.$off('windowResized', this.SET_SCROLLBAR_WIDTH);
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/style.scss";

  :root {
    --text-color: var(--black);
  }

  #app {
    background: var(--white);
    min-height: inherit;
    display: grid;
    grid-template-rows: 1fr;

    .page-container {
      display: grid;
      grid-template-rows: max-content 1fr;
      z-index: 0;
    }

    * {
      color: var(--black);

      .newsletter--mobile button {
        color: var(--white);
      }

      @include mq(m) {
        color: var(--text-color);
      }
    }
  }

  .app-loaded {
    cursor: auto;

    #loader {
      display: none;
    }
  }
</style>
