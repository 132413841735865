<template>
  <div :class="['single', { 'project': $route.meta.type }]">
    <main>
      <h1
        v-if="title && $route.meta.type"
        class="title typo--h1"
        v-html="title"
      />
      <Description
        v-if="description"
        class="first-description"
        :data="description"
      />
      <component
        :is="block.component"
        v-for="(block, index) in blocks"
        :key="block.attrs.id"
        :class="[
          block.blockName === 'acf/media' ? 'media-block' : false,
          index === 0 ? 'first-block' : false,
          block.blockName === 'acf/heading' || block.blockName === 'acf/heading-single' ? 'heading-block' : false,
        ]"
        :data="
          block.blockName === 'core/columns' ? block.innerBlocks :
          block.attrs.data
            ? { ...block.attrs.data, ratio: true }
            : block.innerHTML
        "
      />
    </main>
    <div
      id="overlay-end"
      ref="overlayEnd"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import data from '@/mixins/data';
import TitleSecondPart from '@/components/blocks/title-second-part';
import Description from '@/components/blocks/description';
import Heading from '@/components/blocks/heading';
import Paragraph from '@/components/blocks/paragraph';
import Credits from '@/components/blocks/credits';
import Figure from '@/components/media/figure';
import Gallery from '@/components/media/gallery';
import Video from '@/components/media/video';
import Columns from '@/components/blocks/columns';

export default {
  name: 'Single',
  components: {
    Description,
  },
  mixins: [data],
  emits: ['scrolled'],
  data() {
    return {
      blockComponents: new Map([
        ['acf/title-second-part', TitleSecondPart],
        ['acf/description', Description],
        ['acf/heading', Heading],
        ['acf/heading-single', Heading],
        ['image', Figure],
        ['gallery', Gallery],
        ['video', Video],
        ['core/columns', Columns],
        ['core/paragraph', Paragraph],
        ['acf/credits', Credits],
      ]),
      // overlayEndOpacity: 1,
      observer: null,
    };
  },
  computed: {
    blocks() {
      const blocks = [...this.post.gds_blocks];
      const titleSecondPartIndex = blocks.findIndex(
        (block) => block.blockName === 'acf/title-second-part',
      );
      if (titleSecondPartIndex >= 0) {
        blocks.splice(titleSecondPartIndex, 1);
      }
      const descriptionIndex = blocks.findIndex(
        (block) => block.blockName === 'acf/description',
      );
      if (descriptionIndex >= 0) {
        blocks.splice(descriptionIndex, 1);
      }

      blocks.forEach((block) => {
        let name = block.blockName;

        if (block.blockName === 'acf/media') {
          name = block.attrs.data.type;
        }
        block.component = this.blockComponents.get(name);
      });

      return blocks;
    },
    title() {
      const firstPart = this.post.title.rendered;
      const secondPartBlock = this.post.gds_blocks.find(
        (block) => block.blockName === 'acf/title-second-part',
      );
      const secondPart = secondPartBlock
        ? secondPartBlock.attrs.data.text
        : false;

      let title = false;
      if (firstPart && firstPart !== '') {
        title = firstPart;
        if (secondPart && secondPart !== '') {
          title = `${firstPart}
            ${secondPart}`;
        }
      }

      return title;
    },
    description() {
      const descriptionBlock = this.post.gds_blocks.find(
        (block) => block.blockName === 'acf/description',
      );
      let description = false;
      if (descriptionBlock) {
        description = descriptionBlock.attrs.data;
      }
      return description;
    },
  },
  mounted() {
    if (!this.$store.state.route.from.name) {
      this.lockView({ selector: '.page-container' });
    }
    const observerOptions = {
      threshold: this.observerThreshold(1000),
    };
    this.observer = new IntersectionObserver(
      this.handleObserver,
      observerOptions,
    );
    this.observer.observe(this.$refs.overlayEnd);
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  methods: {
    ...mapActions(['lockView', 'unlockView']),
    observerThreshold(steps) {
      const array = Array(steps + 1)
        .fill(0)
        .map((_, index) => index / steps || 0);

      return array;
    },
    closeSingle() {
      if (!this.$store.state.route.from.name) {
        this.$router.push({ name: 'Home' });
      } else {
        this.$router.back();
      }
    },
    handleObserver(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // this.overlayEndOpacity = 1 - entry.intersectionRatio;
          if (entry.intersectionRatio > 0.99) {
            this.$router.push({ name: 'Home' });
            this.$emit('scrolled');
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
  .single {
    --media-height: 600px;
    --single-columns: 8;

    min-height: calc(var(--vh) * 100);
    z-index: 1;

    main {
      min-height: calc(var(--vh) * 100);
      display: grid;
      grid-template-columns: repeat(var(--single-columns), minmax(0, 1fr));
      column-gap: var(--column-gap-s);
      background: var(--white);
      padding: var(--spacer-s);
      padding-top: calc(
        var(--spacer-s) + var(--spacer-page) + var(--header-height)
      );
      padding-bottom: calc(var(--spacer-s) + var(--spacer-page));
      > * {
        grid-column: 1 / -1;
      }
    }

    @include mq(m) {
      --single-columns: 12;
    }

    .title {
      white-space: pre-line;
    }

    .description {
      grid-column-start: 2;
    }

    .first-description {
      margin-top: var(--spacer-xs);
    }

    .paragraph {
      @include mq(m) {
        grid-column: 4 / span 6;
      }
    }

    .columns {
      margin-bottom: var(--spacer-s);
    }
    .media-block {
      margin: var(--spacer-m) 0;
    }

    .heading-block {
      margin-bottom: var(--spacer-s);
    }

    .first-block {
      margin-top: var(--spacer-xs);
    }

    &.project a {
      text-decoration: underline;
      text-underline-offset: 0.08em;
    }

    #overlay-end {
      height: calc(var(--vh) * 100);
      pointer-events: none;
      // background: var(--white);
    }
  }
</style>
