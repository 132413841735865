import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  state: {
    authLoading: false,
  },
  mutations,
  getters,
  actions,
};
