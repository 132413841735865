<template>
  <div
    tabindex="-1"
    :class="[
      'newsletter',
      theme ? `newsletter--${theme}` : false,
      $route.meta.single ? 'newsletter--overlay' : false,
    ]"
  >
    <form
      v-if="!success && !already"
      action="https://plus.us1.list-manage.com/subscribe/post"
      target="_blank"
      method="POST"
    >
      <input
        type="hidden"
        name="u"
        value="a39913878546e252e8d3f590c"
      >
      <input
        type="hidden"
        name="id"
        value="e0c132282c"
      >
      <div class="fields-container">
        <input
          id="MERGE0"
          v-model="email"
          :class="theme === 'mobile' ? 'typo--p' : 'typo--h2'"
          size="1"
          type="email"
          autocapitalize="off"
          autocorrect="off"
          name="MERGE0"
          :placeholder="$labels.newsletter_placeholder"
          required
        >
        <div class="privacy-policy-container">
          <input
            id="MERGE1"
            v-model="name"
            :class="theme === 'mobile' ? 'typo--p' : 'typo--h2'"
            size="1"
            type="text"
            autocapitalize="off"
            autocorrect="off"
            name="MERGE1"
            :placeholder="$labels.newsletter_name_placeholder"
            required
          >
          <input
            id="MERGE2"
            v-model="surname"
            :class="theme === 'mobile' ? 'typo--p' : 'typo--h2'"
            size="1"
            type="text"
            autocapitalize="off"
            autocorrect="off"
            name="MERGE2"
            :placeholder="$labels.newsletter_surname_placeholder"
            required
          >
          <div class="privacy-policy">
            <input
              id="privacy-policy"
              v-model="privacyPolicyAccepted"
              type="checkbox"
              required
            >
            <label
              class="typo--link"
              for="privacy-policy"
            >
              {{ $labels.privacy_policy_cta(1) }}
              <router-link
                :class="['link', $mq.isMobile ? 'link--reverse' : false]"
                to="/privacy-policy"
              >
                {{ $labels.privacy_policy_cta() }}</router-link>.
            </label>
          </div>
        </div>
      </div>
      <button class="typo--h2">
        {{ $labels.newsletter_cta }}
      </button>
    </form>
    <div
      v-else
      class="t-uppercase typo--h2"
    >
      {{
        already
          ? $labels.newsletter_already_message
          : $labels.newsletter_success_message
      }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Newsletter',
  props: {
    theme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      email: '',
      name: '',
      surname: '',
      success: false,
      already: false,
      avoid: false,
      privacyPolicyAccepted: false,
    };
  },
  computed: {
    ...mapGetters(['options']),
  },
  methods: {
    submit() {
      document.body.classList.remove('app-loaded');
      if (this.avoid) return;

      this.avoid = true;
      const request = {
        fn: 'mailchimp',
        params: {
          list_id: this.options.extra.list_id.value,
          email: this.email,
          name: this.name,
          surname: this.surname,
        },
      };

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(this.options.extra.recaptcha_public_key.value, {
            action: 'submit',
          })
          .then((token) => {
            request.params.token = token;

            this.$store
              .dispatch('sendRequest', request)
              .then((response) => {
                if (
                  response.data.status
                    && response.data.status === 'subscribed'
                ) {
                  this.success = true;
                } else if (response.data.title === 'Member Exists') {
                  this.already = true;
                } else {
                  this.success = false;
                  this.avoid = false;
                }
                document.body.classList.add('app-loaded');
              })
              .catch((error) => {
                this.success = false;
                this.avoid = false;
                console.log(error);
              });
          });
      });
    },
  },
};
</script>

<style lang="scss">
  .newsletter {
    --checkbox-size: 12px;
    --checkbox-inside-size: 8px;

    z-index: 1;

    .fields-container {
      width: 100%;
      display: grid;
      gap: var(--row-gap-xxs);
    }

    form {
      align-items: start;
      gap: var(--column-gap-s);
      display: grid;
      grid-template-columns: minmax(0, 1fr) auto;
      position: relative;
    }

    &:focus-within .privacy-policy-container {
      display: grid;
    }

    input[type="text"] {
      border-radius: var(--border-radius);
      border: var(--line) var(--grey) solid;
      min-height: 28px;
      padding-left: var(--spacer-xs);
      padding-right: var(--spacer-xs);
      // filter: invert(1);
      color: var(--black);
      cursor: text;
      z-index: 1;
    }

    input[type="email"] {
      border-radius: var(--border-radius);
      border: var(--line) var(--grey) solid;
      min-height: 28px;
      // filter: invert(1);
      color: var(--black);
      cursor: text;
      z-index: 1;
      padding: 0 var(--spacer-xs);
    }

    .privacy-policy-container {
      display: none;
      width: 100%;
      row-gap: var(--row-gap-xxs);
      top: 0;
    }

    .privacy-policy {
      align-items: center;
      column-gap: var(--column-gap-xs);
      display: grid;
      grid-auto-flow: column;
      justify-self: start;

      input[type="checkbox"] {
        border-radius: var(--border-radius);
        border: var(--line) var(--grey) solid;
        display: grid;
        min-height: var(--checkbox-size);
        min-width: var(--checkbox-size);
        place-items: center;
        // filter: invert(1);

        > * {
          grid-area: 1 / -1;
        }

        &:checked::after {
          --border-radius: 2px;

          background: var(--black);
          border-radius: var(--border-radius);
          content: "";
          min-height: var(--checkbox-inside-size);
          min-width: var(--checkbox-inside-size);
        }
      }
    }

    button {
      text-transform: lowercase;
      padding: 0;
      @include mq(m) {
        padding: 10px 0;
      }
    }

    &--mobile {
      --checkbox-size: 20px;
      --checkbox-inside-size: 12px;

      background: var(--black);
      color: var(--white);
      display: grid;
      position: sticky;
      min-height: 44px;
      align-items: center;
      bottom: 0;
      width: 100%;
      padding: var(--spacer-s);
      row-gap: var(--row-gap-s);
      align-self: end;

      &.newsletter--overlay {
        position: fixed;
      }

      input[type="text"] {
        border: initial;
        padding: 0;
        // filter: invert(0);
        color: var(--white);
        font-size: 16px;
      }

      input[type="email"] {
        border: initial;
        padding: 0;
        // filter: invert(0);
        color: var(--white);
        font-size: 16px;
      }

      .privacy-policy-container {
        display: none;
        position: initial;
        grid-column: 1;
        margin-bottom: var(--spacer-xxs);

        input[type="checkbox"] {
          border-color: var(--white);
          // filter: invert(0);

          &:checked::after {
            background: var(--white);
          }
        }
      }

      .privacy-policy * {
        color: var(--white) !important;
      }

      button {
        border: var(--line) var(--white) solid;
        border-radius: var(--border-radius);
        padding: var(--spacer-xs);
        grid-row: 1;
        grid-column: 2;
      }

      @include mq(m) {
        display: none;
      }
    }
  }
</style>
