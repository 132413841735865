<template>
  <div
    :class="[
      'cta',
      `cta--${theme}`,
      data.size ? `typo--${data.size}` : null,
      disabled ? 'cta-disabled' : null,
    ]"
  >
    <template v-if="data.fn">
      <button @click="!disabled ? data.fn() : null">
        {{ data.title }}
      </button>
    </template>
    <template v-else-if="modal">
      <button @click="openModal">
        {{ data.title }}
      </button>
    </template>
    <template v-else-if="hash">
      <button @click="goTo">
        {{ data.title }}
      </button>
    </template>
    <template v-else-if="external">
      <a
        :href="data.url"
        :target="data.target ? data.target : '_blank'"
      >
        {{ data.title }}
      </a>
    </template>
    <template v-else>
      <router-link :to="url">
        {{ data.title }}
      </router-link>
    </template>
  </div>
</template>

<script>
import gsap, { ScrollToPlugin } from 'gsap/all';

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'Cta',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    url() {
      if (
        this.data.url
          && typeof this.data.url === 'string'
          && process.env.VUE_APP_BASE_URL
          && process.env.VUE_APP_BASE_URL !== '/'
      ) {
        return this.data.url.replace(process.env.VUE_APP_BASE_URL, '/');
      }
      return this.data.url;
    },
    external() {
      return (
        this.url
          && typeof this.url === 'string'
          && (this.url.startsWith('http') || this.url.startsWith('mailto'))
      );
    },
    hash() {
      return (
        this.url && typeof this.url === 'string' && this.url.startsWith('#')
      );
    },
    modal() {
      return (
        this.url
          && typeof this.url === 'string'
          && this.url.startsWith('#modal-')
      );
    },
  },
  methods: {
    openModal() {
      if (!this.disabled) {
        const id = this.data.url.replace('#modal-', '');
        this.$bus.$emit('openModal', id);
      }
    },
    goTo() {
      if (!this.disabled) {
        const hash = this.data.url;
        gsap.killTweensOf(window);
        gsap.to(window, {
          scrollTo: {
            y: document.querySelector(hash),
            offsetY: 0, // header height
            autoKill: true,
          },
          duration: 1,
          ease: 'expo.out',
        });
      }
    },
  },
};
</script>

<style lang="scss">
  .cta {
    display: grid;

    &--default {
      a,
      button {
        @extend %typo--h2;
        text-transform: uppercase;
      }
    }

    // &--link {
    //   @media (hover: hover) {
    //     &:hover {
    //       a,
    //       button {
    //         color: var(--white);
    //         background: var(--black);
    //       }
    //     }
    //   }
    // }
  }
</style>
