var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"thumb"},[_c('router-link',{staticClass:"title typo--h1",attrs:{"to":_vm.data.link}},[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.data.title.rendered)},on:{"mouseenter":function($event){return _vm.setTextColor(_vm.data.acf?.main_content_text_color)},"mouseleave":function($event){return _vm.setTextColor('black')}}})]),(
      _vm.$route.name === 'Home' &&
        _vm.data.acf?.main_content_active &&
        _vm.data.acf?.main_content_type === 'video' &&
        _vm.data.acf?.main_content_video?.url
    )?_c('video',{staticClass:"background-video",attrs:{"autoplay":"","muted":"","loop":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.data.acf?.main_content_video.url,"type":"video/mp4"}})]):(_vm.$route.name === 'Home')?_c('div',{staticClass:"background-image",style:(_vm.data.acf?.main_content_image && _vm.data.acf?.main_content_image?.url
        ? { backgroundImage: `url(${_vm.data.acf.main_content_image.url})` }
        : { backgroundColor: 'var(--black)' })}):_vm._e(),(_vm.$mq.isMobile && _vm.data.acf?.main_content_active)?_c('router-link',{attrs:{"to":_vm.data.link}},[(_vm.data.acf?.main_content_image && _vm.data.acf?.main_content_image?.url)?_c('img',{staticClass:"thumb-image",attrs:{"src":_vm.data.acf?.main_content_image?.url}}):(_vm.data.acf?.main_content_type === 'video' && _vm.data.acf?.main_content_video?.url)?_c('video',{staticClass:"thumb-video",attrs:{"autoplay":"","muted":"","loop":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.data.acf?.main_content_video.url,"type":"video/mp4"}})]):_vm._e()]):_vm._e(),(
      _vm.data.acf.external_links &&
        _vm.data.acf.external_links.length > 0 &&
        !_vm.$mq.isMobile
    )?_c('div',{staticClass:"typo--link external-links"},_vm._l((_vm.data.acf.external_links),function(item,externalIndex){return _c('Cta',{directives:[{name:"show",rawName:"v-show",value:(item.link.url !== ''),expression:"item.link.url !== ''"}],key:externalIndex,attrs:{"data":{
        url: item.link?.url || '/',
        target: item.link.target,
        title: item.link.title,
      },"theme":"link"}})}),1):_vm._e(),(
      _vm.data.acf.press_links && _vm.data.acf.press_links.length > 0 && !_vm.$mq.isMobile
    )?_c('div',{staticClass:"typo--link press-links"},_vm._l((_vm.data.acf.press_links),function(item,pressIndex){return _c('Cta',{directives:[{name:"show",rawName:"v-show",value:(item.link.url !== ''),expression:"item.link.url !== ''"}],key:pressIndex,attrs:{"data":{
        url: item.link?.url || '/',
        target: item.link.target,
        title: item.link.title,
      },"theme":"link"}})}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }