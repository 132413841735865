import { relativeUrl } from '@/assets/js/utils';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// eslint-disable-next-line no-undef
const { state, routing } = __VUE_WORDPRESS__;

export default {
  state: relativeUrl(state, routing.url),
  mutations,
  getters,
  actions,
};
