<template>
  <header
    class="header"
  >
    <nav
      :class="{'overlay-page': $route?.meta?.single}"
    >
      <Cta
        v-for="item in menu.items"
        :key="item.id"
        :data="{
          title: item.content,
          url: item.url,
        }"
      />
      <transition
        v-if="$route.name === 'Home'"
        mode="out-in"
        :css="false"
        @before-enter="beforeEnterNewsletter"
        @enter="enterNewsletter"
        @leave="leave"
      >
        <Newsletter v-if="!$mq.isMobile && $route.name === 'Home'" />
      </transition>

      <transition
        v-else-if="$route?.meta?.single"
        mode="out-in"
        :css="false"
        @before-enter="beforeEnter"
        @enter="enter"
      >
        <Cta
          v-if="$route?.meta?.single"
          :data="{
            title: 'X',
            url: '/',
          }"
          class="close-button"
        />
      </transition>
    </nav>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import Newsletter from '@/components/blocks/newsletter.vue';
import Cta from '@/components/typo/cta.vue';
import { animate } from 'motion';

export default {
  name: 'Header',
  components: {
    Cta,
    Newsletter,
  },
  computed: {
    ...mapGetters({ getMenu: 'menu' }),
    menu() {
      return this.getMenu('Header');
    },
  },
  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = 'translateY(30px)';
    },
    enter(el, done) {
      animate(el, {
        transform: 'translateY(0)',
        opacity: 1,
      }).finished.then(() => {
        done();
      });
    },
    leave(el, done) {
      animate(el, {
        transform: 'translateY(-30px)',
        opacity: 0,
      }).finished.then(() => {
        done();
      });
    },
    beforeEnterNewsletter(el) {
      el.style.opacity = 0;
    },
    enterNewsletter(el, done) {
      animate(el, {
        opacity: 1,
      }).finished.then(() => {
        done();
      });
    },
  },
};
</script>

<style lang="scss">
  .header {
    align-items: start;
    color: var(--black);
    display: grid;
    min-height: var(--header-height);
    // mix-blend-mode: difference;
    padding: 0 var(--spacer-s);
    position: fixed;
    top: 0;
    user-select: none;
    width: 100%;
    z-index: 2;

    @include mq(m) {
      padding: var(--spacer-xs) var(--spacer-s);
    }

    nav {
      align-items: start;
      column-gap: var(--column-gap-s);
      display: grid;
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-flow: column;
      grid-template-columns: repeat(4, minmax(0, 1fr));

      .cta:last-child {
        grid-column-end: -1;
        justify-self: end;
        @include mq(m) {
          grid-column-end: unset;
        }
      }

      .cta {
        padding: 10px 0;
      }

      @include mq(m) {
        display: grid;
        grid-template-columns: initial;
      }

      &.overlay-page div:nth-child(3) {
        display: none;
        @include mq(m) {
          display: grid;
        }
      }
    }

    .newsletter {
      display: none;

      @include mq(m) {
        display: grid;
      }
    }

    .back-button {
      padding: 0;
      text-transform: uppercase;
      @include mq(m) {
        padding: 10px 0;
      }
    }
  }
</style>
