export default {
  lang: (state) => state.lang,
  defaultLang: (state) => state.wp.i18n.default,
  langs: (state) => state.wp.i18n.langs,
  currentPost: (state) => state.currentPost,
  transition: (state) => state.transition,
  snackbar: (state) => state.snackbar,
  scrollbarWidth: (state) => state.scrollbarWidth,
  top(state) {
    return `-${state.scrollY}px`;
  },
};
