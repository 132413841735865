<template>
  <div
    v-if="data.active"
    class="description typo--caption"
    v-html="data.description"
  />
</template>

<script>
// import Cta from '@/components/typo/cta.vue';

export default {
  name: 'Description',
  components: {
    // Cta,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
  .description {
   text-transform: uppercase;
  }
</style>
