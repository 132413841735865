<template>
  <div
    :style="{
      gridTemplateColumns: !$mq.isMobile
        ? `repeat(${data.length}, minmax(0, 1fr))`
        : '1fr',
    }"
    class="columns"
  >
    <div
      v-for="(blocks, columnIndex) in columns"
      :key="columnIndex"
    >
      <component
        :is="block.component"
        v-for="block in blocks"
        :key="block.attrs.id"
        :data="
          block.blockName === 'core/columns' ? block.innerBlocks :
          block.attrs.data
            ? { ...block.attrs.data, ratio: true }
            : block.innerHTML
        "
      />
    </div>
  </div>
</template>

<script>
import Paragraph from '@/components/blocks/paragraph';
import Figure from '@/components/media/figure';
import Gallery from '@/components/media/gallery';
import Video from '@/components/media/video';

export default {
  name: 'Columns',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      blockComponents: new Map([['core/paragraph', Paragraph], ['image', Figure], ['gallery', Gallery], ['video', Video]]),
    };
  },
  computed: {
    columns() {
      const columns = [];
      this.data.forEach((column) => columns.push(column.innerBlocks));

      const blocks = [];
      columns.forEach((column) => column.forEach((block) => blocks.push(block)));

      blocks.forEach((block) => {
        let name = block.blockName;

        if (block.blockName === 'acf/media') {
          name = block.attrs.data.type;
        }

        block.component = this.blockComponents.get(name);
      });

      return columns;
    },
  },
};
</script>

<style lang="scss">
  .columns {
    grid-column: 1 / -1;
    display: grid;
    column-gap: var(--column-gap-s);
    row-gap: var(--row-gap-m);
  }

  .columns img {
    width: 100%;
    object-position: left;
  }
</style>
