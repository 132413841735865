export default {
  SET_CART(state, value) {
    state.cart = value;
  },
  SET_CART_TOTALS(state, value) {
    state.cartTotals = value;
  },
  SET_COUPONS(state, value) {
    state.coupons = value;
  },
  SET_SNACKBAR(state, value) {
    state.snackbar = value;
  },
  SET_CART_LOADING(state, value) {
    if (value) {
      document.querySelector('html').classList.add('wc-cart-loading');
    } else {
      document.querySelector('html').classList.remove('wc-cart-loading');
    }
    state.cartLoading = value;
  },
  SET_USER(state, value) {
    state.user = value;
  },
  SET_CUSTOMER(state, value) {
    if (value) {
      state.customer = { ...state.customer, ...value };
    } else {
      state.customer = null;
    }
  },
  SET_CUSTOMER_SAVED_METHODS(state, value) {
    if (value) {
      state.customerSavedMethods = value;
    } else {
      state.customerSavedMethods = null;
    }
  },
  SET_ORDERS(state, value) {
    state.customer = { ...state.customer, orders: value };
  },
  TOGGLE_CART(state) {
    state.showCart = !state.showCart;
    if (state.showCart) {
      // eslint-disable-next-line no-underscore-dangle
      this._vm.$bus.$emit('viewCart', state.cart);
    }
  },
  OPEN_CART(state) {
    state.showCart = true;
    // eslint-disable-next-line no-underscore-dangle
    this._vm.$bus.$emit('viewCart', state.cart);
  },
  CLOSE_CART(state) {
    state.showCart = false;
  },
  SET_PAYMENT_GATEWAYS_ACTIVE(state, value) {
    state.paymentGatewaysActive = value;
  },
  SET_PAYMENT_METAS(state, value) {
    if (!state.paymentMetas[value.id]) {
      state.paymentMetas[value.id] = {};
    }
    state.paymentMetas[value.id][value.key] = value.meta;
  },
  SET_COUNTRIES(state, value) {
    state.countries = value;
  },
  SET_CUSTOMER_SHIPPING_METHODS(state, value) {
    state.customerShippingMethods = value;
  },
  SET_SUBSCRIPTIONS(state, value) {
    state.customer = { ...state.customer, subscriptions: value };
  },
};
