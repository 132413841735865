export default {
  SET_LANG(state, value) {
    state.lang = value;
  },
  SET_CURRENT_POST(state, value) {
    state.currentPost = value;
  },
  SET_TRANSITION(state, value) {
    state.transition = value;
  },
  SET_SNACKBAR(state, value) {
    state.snackbar = value;
  },
  LOCK_VIEW(state, { selector, avoidScroll, getters }) {
    const view = document.querySelector(selector);
    if (!avoidScroll) {
      state.scrollY = window.scrollY;
    }
    view.style.position = 'fixed';
    view.style.width = '100%';
    view.style.top = getters.top;
    view.style.pointerEvents = 'none';
  },
  UNLOCK_VIEW(state, { selector1, selector2 }) {
    const view = document.querySelector(selector1);
    const overlay = document.querySelector(selector2);
    overlay.style.display = 'none';
    view.removeAttribute('style');
    setTimeout(() => {
      window.scrollTo(0, state.scrollY);
    }, 0);
  },
  CREATE_SCROLLBAR() {
    const scrollbar = document.createElement('div');
    scrollbar.id = 'scrollbar';
    scrollbar.style.overflowY = 'scroll';
    scrollbar.style.position = 'absolute';
    document.body.append(scrollbar);
  },
  SET_SCROLLBAR_WIDTH(state) {
    const scrollbar = document.querySelector('#scrollbar');
    const scrollbarWidth = scrollbar.offsetWidth;

    state.scrollbarWidth = scrollbarWidth;
    document.documentElement.style.setProperty(
      '--scrollbar-width',
      `${scrollbarWidth}px`,
    );
  },
};
