<template>
  <figure
    :class="[
      'figure',
      data.align ? `figure--${data.align}` : false,
      gallery ? 'figure--gallery' : false,
    ]"
  >
    <img
      ref="image"
      :class="['image', data.align ? `image--${data.align}` : false]"
      :src="src"
      :alt="image.alt"
    >
    <figcaption
      v-if="!hideCaption && image.caption"
      class="typo--caption"
    >
      {{ image.caption }}
    </figcaption>
  </figure>
</template>

<script>
export default {
  name: 'Figure',
  props: {
    data: {
      type: Object,
      required: true,
    },
    hideCaption: {
      type: Boolean,
      default: false,
    },
    gallery: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      currentImg: 'default',
      src: null,
      ratio: 0,
      defaultHeight: 0,
    };
  },
  computed: {
    image() {
      if (this.gallery) {
        return this.data;
      }
      return this.data.image;
    },
  },
  created() {
    this.src = this?.image?.sizes?.l;
  },
  mounted() {
    this.currentSrc();
    this.$bus.$on('windowResized', this.currentSrc);
  },
  beforeDestroy() {
    this.$bus.$off('windowResized', this.currentSrc);
  },
  methods: {
    currentSrc() {
      let src = this?.image?.sizes?.l;
      const { sizes } = this.image;
      const ratio = window.devicePixelRatio >= 2 ? 2 : 1;
      const wrapper = this.$parent.$el.offsetWidth;
      const dimension = 'width';
      const max = wrapper >= sizes[`xxl-${dimension}`] ? wrapper : wrapper * ratio;
      const ranges = {
        // xxs: sizes[`xxs-${dimension}`],
        xs: sizes[`xs-${dimension}`],
        s: sizes[`s-${dimension}`],
        m: sizes[`m-${dimension}`],
        l: sizes[`l-${dimension}`],
        xl: sizes[`xl-${dimension}`],
        // xxl: sizes[`xxl-${dimension}`],
      };

      const sizesRange = Object.keys(ranges).filter(
        (key) => ranges[key] >= max,
      );
      const size = sizesRange.length > 0 ? sizesRange[0] : 'l';

      this.$nextTick(() => {
        if (this.data.subtype === 'gif') {
          src = this.image.url;
        } else if (this.$mq.isMobile && this.data.mobile) {
          src = this.data.mobile.sizes[size];
        } else {
          src = this.image.sizes[size];
        }

        this.src = src;
      });
    },
  },
};
</script>

<style lang="scss">
  .figure {
    display: grid;
    grid-template-columns: repeat(var(--single-columns), minmax(0, 1fr));
    column-gap: var(--spacer-s);

    &--gallery {
      display: block;
      height: 100%;

      .image {
        height: 100%;
        max-height: initial;
      }
    }

    .image {
      grid-column: 1 / -1;
      max-width: 100%;
      max-height: var(--media-height);
      object-fit: contain;

      &--center {
        @include mq(m) {
          grid-column-start: 4;
        }
      }
    }

    figcaption {
      padding-top: var(--spacer-xs);
      grid-column: 2 / -1;
    }
  }
</style>
