<template>
  <div class="gallery-container">
    <div
      ref="gallery"
      :class="['gallery', data.align === 'center' ? 'gallery--center' : false]"
      :style="{
        height: height > 0 ? `${height}px` : `${defaultHeight}px`,
      }"
    >
      <div
        v-for="(item, index) in data.gallery"
        :key="index"
        ref="items"
        class="item"
      >
        <div
          :class="[
            'figure-container',
            item.type === 'video' ? 'figure-container--video' : false,
          ]"
          @mouseout="resetCursor"
        >
          <component
            :is="item.type === 'video' ? 'Video' : 'Figure'"
            ref="figures"
            :hide-caption="true"
            :gallery="true"
            :data="item"
            @set-previous-width="setPreviousWidth"
          />
          <button
            class="previous"
            :style="{ width: item.type === 'video' ? previousWidth : '50%' }"
            @mouseenter="setCursor"
            @mousemove="setCursor"
            @click="previous"
          />
          <button
            :style="{ width: `${targetWidth}px` }"
            class="next"
            @mouseenter="setCursor"
            @mousemove="setCursor"
            @click="next"
          />
        </div>
      </div>
    </div>
    <div
      v-if="gallery"
      class="counter typo--caption"
    >
      {{ `${gallery.selectedIndex + 1}/${data.gallery.length}` }}
    </div>
    <figcaption
      v-if="gallery ? data.gallery[gallery.selectedIndex].caption : false"
      class="typo--caption"
    >
      {{ data.gallery[gallery.selectedIndex].caption }}
    </figcaption>
    <div
      :style="{ top: cursor.top, left: cursor.left, display: cursor.display }"
      class="cursor typo--h2"
    >
      {{ cursor.text }}
    </div>
  </div>
</template>

<script>
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';

import Figure from '@/components/media/figure';
import Video from '@/components/media/video';

require('flickity-imagesloaded');

export default {
  name: 'Gallery',
  components: {
    Figure,
    Video,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    extraSettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      gallery: null,
      targetItem: 0,
      targetWidth: 0,
      defaultHeight: 0,
      previousWidth: '50%',
      height: 0,
      cursor: {
        text: 'previous',
        display: 'none',
        top: 0,
        left: 0,
      },
      settings: {
        cellAlign: 'left',
        cellSelector: '.item',
        imagesLoaded: true,
        prevNextButtons: false,
        pageDots: false,
        draggable: !!this.$mq.isTouchDevice,
        setGallerySize: false,
        wrapAround: true,
      },
    };
  },
  mounted() {
    this.defaultHeight = parseInt(
      window
        .getComputedStyle(document.querySelector('.single'))
        .getPropertyValue('--media-height'),
      10,
    );
    this.gallery = new Flickity(this.$refs.gallery, {
      ...this.settings,
      ...this.extraSettings,
      on: {
        ready: () => {
          this.setTargetItem();
        },
      },
    });
    this.$bus.$on('windowResized', this.handleResize);
  },
  beforeUnmount() {
    this.gallery.destroy();
  },
  beforeDestroy() {
    this.$bus.$off('windowResized', this.handleResize);
  },
  methods: {
    setCursor(event) {
      this.cursor.display = 'initial';
      this.cursor.text = event.target.className;
      this.cursor.top = `${event.pageY}px`;
      this.cursor.left = `${event.pageX}px`;
    },
    resetCursor() {
      this.cursor.display = 'none';
    },
    next() {
      this.gallery.next(false, true);
    },
    previous() {
      this.gallery.previous(false, true);
    },
    setTargetItem() {
      const images = this.data.gallery;
      let targetRatio = 0;

      images.forEach((image, index) => {
        const { width } = image;
        const { height } = image;
        const ratio = width / height;

        if (ratio > targetRatio) {
          targetRatio = ratio;
          this.targetItem = index;
        }
      });

      this.setHeight(this.targetItem);
      this.setWidth(this.targetItem);
    },
    setWidth(index) {
      let height = this.defaultHeight;
      if (this.height > 0) {
        height = this.height;
      }

      this.targetWidth = height
          * (this.data.gallery[index].width / this.data.gallery[index].height);
    },
    setHeight(index) {
      this.height = this.defaultHeight;

      const targetHeight = this.$refs.gallery.clientWidth
          * (this.data.gallery[index].height / this.data.gallery[index].width);

      if (targetHeight < this.defaultHeight) {
        this.height = targetHeight;
      } else this.height = 0;
    },
    handleResize() {
      this.setHeight(this.targetItem);
      this.setWidth(this.targetItem);
    },
    setPreviousWidth(width) {
      this.previousWidth = `${width / 2}px`;
    },
  },
};
</script>

<style lang="scss">
  .gallery-container {
    display: grid;
    grid-template-columns: repeat(var(--single-columns), minmax(0, 1fr));
    column-gap: var(--spacer-s);

    .gallery {
      grid-column: 1 / -1;

      &--center {
        @include mq(m) {
          grid-column-start: 4;
        }
      }

      .item {
        width: 100%;
        height: 100%;
        margin-right: calc(var(--spacer-s) * 2);

        .figure-container {
          width: fit-content;
          position: relative;
          height: 100%;

          &--video {
            width: initial;
          }

          button {
            display: none;
            @media (hover: hover) {
              display: initial;
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              cursor: none;
            }
          }

          .previous {
            z-index: 2;
          }
        }
      }
    }

    .counter {
      margin-top: var(--spacer-xs);
    }

    figcaption {
      grid-column: 2 / -1;
      margin-top: var(--spacer-xs);
    }

    .cursor {
      display: none;
      @media (hover: hover) {
        display: initial;
        color: var(--black);
        // mix-blend-mode: difference;
        pointer-events: none;
        position: absolute;
        text-transform: uppercase;
        transform: translate(-50%, -50%);
      }
    }
  }
</style>
