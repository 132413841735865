<template>
  <footer
    class="footer"
  >
    <Cta
      v-show="!$mq.isMobile"
      v-if="footer.address && footer.address !== ''"
      :data="{
        url: footer.address.url,
        target: footer.address.target,
        title: footer.address.title,
      }"
    />
    <Cta
      v-show="!$mq.isMobile"
      v-if="footer.email && footer.email !== ''"
      :data="{
        url: footer.email.url,
        target: footer.email.target,
        title: footer.email.title,
      }"
    />
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';

export default {
  name: 'Footer',
  components: {
    Cta,
  },
  computed: {
    ...mapGetters(['options']),
    footer() {
      const { footer } = this.options;
      return footer;
    },
  },
};
</script>

<style lang="scss">
  .footer {
    margin-top: var(--row-gap-l);
    display: grid;
    grid-auto-flow: column;
    column-gap: var(--column-gap-s);
    align-self: end;
    padding: var(--spacer-s);
  }
</style>
