var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[(_vm.footer.address && _vm.footer.address !== '')?_c('Cta',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$mq.isMobile),expression:"!$mq.isMobile"}],attrs:{"data":{
      url: _vm.footer.address.url,
      target: _vm.footer.address.target,
      title: _vm.footer.address.title,
    }}}):_vm._e(),(_vm.footer.email && _vm.footer.email !== '')?_c('Cta',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$mq.isMobile),expression:"!$mq.isMobile"}],attrs:{"data":{
      url: _vm.footer.email.url,
      target: _vm.footer.email.target,
      title: _vm.footer.email.title,
    }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }