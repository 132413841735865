<template>
  <div
    v-if="data !== '\n<p></p>\n'"
    :class="['paragraph', `typo--${typo}`]"
    v-html="data"
  />
</template>

<script>
export default {
  name: 'Paragraph',
  props: {
    data: {
      type: String,
      default: '',
    },
    typo: {
      type: String,
      default: 'p',
    },
  },
};
</script>

<style lang="scss">
  .paragraph {
    max-width: 700px;

    ul {
      padding-left: calc(var(--spacer-s) * 3);
      @include mq(m) {
        padding-left: calc(var(--spacer-s) * 2);
      }
    }
  }

  .paragraph.typo--caption::before,
  .paragraph.typo--caption::after {
    margin: 0;
  }

  .paragraph:not(:last-child)::after {
    content: " ";
    display: block;
    white-space: pre;
  }
</style>
