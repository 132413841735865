<template>
  <div
    v-if="data.active"
    class="credits"
  >
    <div
      v-for="(group, index) in data.credits"
      :key="index"
      class="group"
    >
      <h6 class="label typo--caption">
        {{ group.label }}
      </h6>
      <Paragraph
        class="text"
        typo="caption"
        :data="group.text"
      />
    </div>
  </div>
</template>

<script>
import Paragraph from '@/components/blocks/paragraph.vue';

export default {
  name: 'Credits',
  components: {
    Paragraph,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
  .credits {
    margin-top: var(--spacer-page);
    display: grid;

    .group {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: minmax(0, 1fr);

      @include mq(m) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }

      .label {
        text-transform: uppercase;
        &::before,
        &::after {
          margin: 0;
        }
      }

      .text {
        @include mq(m) {
          grid-column: span 2;
        }
      }
    }
  }
</style>
