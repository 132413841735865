/* eslint-disable no-undef */

import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import Page from '@/views/Page.vue';
import Single from '@/views/Single.vue';
import NotFound from '@/views/404.vue';
import Preview from '@/views/Preview.vue';

const singles = new Map();

// HINT: Add custom Post Type - 2
singles.set('post', Single);
singles.set('project', Single);
singles.set('academic', Single);
singles.set('before_2050', Single);

Vue.use(VueRouter);

const { show_on_front, page_on_front } = __VUE_WORDPRESS__.routing;

const { postTypes, i18n } = __VUE_WORDPRESS__.state;

const { name: siteTitle, description } = __VUE_WORDPRESS__.state.site;

let langs = null;

if (i18n) {
  store.commit('ADD_LANGUAGES', Object.keys(i18n.langs));

  langs = Object.keys(i18n.langs).filter((lang) => lang !== i18n.default);
  langs = langs.join('|');
} else {
  store.commit('ADD_LANGUAGES', ['default']);
}

const baseRoutes = [
  {
    path: langs ? `/:lang(${langs})?/404` : '/404',
    name: '404',
    component: NotFound,
    meta: {
      slug: page_on_front,
      type: show_on_front ? 'pages' : 'posts',
    },
  },
  {
    path: langs ? `/:lang(${langs})?/` : '/',
    name: 'Home',
    component: Page,
    props: {
      hideTitle: true,
    },
    meta: {
      slug: page_on_front,
      type: show_on_front ? 'pages' : 'posts',
    },
  },
  {
    path: langs ? `/:lang(${langs})?/:slug` : '/:slug',
    name: 'Page',
    components: {
      default: Page,
      single: Single,
    },
    props: {
      default: {
        slug: page_on_front,
        type: 'pages',
        hideTitle: true,
      },
    },
    meta: {
      single: true,
    },
  },
  {
    path: langs ? `/:lang(${langs})?/preview/:type/:id` : '/preview/:type/:id',
    name: 'Preview',
    component: Preview,
  },
];

const routes = baseRoutes;

Object.keys(postTypes).forEach((key) => {
  if (key !== 'page' && key !== 'product') {
    routes.push({
      path: langs
        ? `/:lang(${langs})?/${postTypes[key].rewrite.slug}/:slug`
        : `/${postTypes[key].rewrite.slug}/:slug`,
      name: postTypes[key].label,
      components: {
        default: Page,
        single: singles.get(key),
      },
      props: {
        default: {
          slug: page_on_front,
          type: 'pages',
          hideTitle: true,
        },
      },
      meta: {
        single: true,
        type: postTypes[key].rest_base,
      },
    });
  }
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && !to.meta.single) return savedPosition;
    if (from.meta.single) {
      return false;
    }
    if (from.path !== to.path) {
      return { x: 0, y: 0 };
    }
    return false;
  },
});

router.beforeEach(async (to, from, next) => {
  if (document.body.classList.contains('app-loaded')) {
    document.body.classList.remove('app-loaded');
  }
  if (to.meta.fetchCountry) {
    await store.dispatch('getCountries');
  }

  if (from.path !== to.path) {
    store.commit('SET_CURRENT_POST', null);
  }

  if (to.meta.customView || to.name === 'Search' || to.name === 'Preview') {
    document.title = `${to.name} — ${siteTitle}`;
    document.body.classList.add('app-loaded');

    next();
    return;
  }

  const slug = to.meta.slug ? to.meta.slug : to.params.slug;
  const lang = to.params.lang
    ? to.params.lang
    : i18n
      ? i18n.default
      : 'default';

  store.commit('SET_LANG', lang);

  const request = {
    type: to.meta.type || 'pages',
    slug,
    lang,
  };

  const promises = [];

  promises.push(store.dispatch('getSingleBySlug', request));

  if (to.meta.single && from.name) {
    store.dispatch('lockView', { selector: '.page-container', avoidScroll: from.meta.single });
  }

  if (!from.name && to.meta.single) {
    const defaultRequest = {
      type: to.matched[0].props.default.type,
      slug: to.matched[0].props.default.slug,
      lang,
    };

    promises.push(store.dispatch('getSingleBySlug', defaultRequest));
  }

  Promise.all(promises).then((res) => {
    document.body.classList.add('app-loaded');

    const page = res[0];

    if (page && slug) {
      // Redirect if hidden product
      if (page.catalog_visibility && page.catalog_visibility === 'hidden') {
        router.push({ name: '404' });
      }
      if (to.path === '/') {
        document.title = `${siteTitle} ${
          description ? `- ${description}` : ''
        }`;
      } else {
        // Avoid HTML entities in title
        const p = document.createElement('p');
        p.innerHTML = page.title ? page.title.rendered : page.name;
        document.title = `${p.innerText} — ${siteTitle}`;
      }

      if (i18n) {
        const { default_locale } = i18n.langs[lang];
        document.documentElement.lang = default_locale.replace('_', '-');
      }

      next();
    } else {
      router.push({ name: '404' });
    }
  });
});

export default router;
