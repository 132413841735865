<template>
  <main
    :class="[
      'page',
      $route.params.slug ? `page--${$route.params.slug}` : 'page--home',
    ]"
  >
    <Spacer
      v-if="!hideTitle && $route.params.slug !== 'contacts'"
      bottom="m"
      tag="h1"
      class="page-title typo--h1"
    >
      {{ post.title.rendered }}
    </Spacer>
    <component
      :is="block.component"
      v-for="(block, index) in blocks"
      v-show="showArchive(block)"
      :key="index"
      :data="blockData(block)"
      @archive-ready="loadedArchives++"
    />
  </main>
</template>

<script>
import data from '@/mixins/data';
import Heading from '@/components/blocks/heading';
import Archive from '@/components/blocks/archive';
import Columns from '@/components/blocks/columns';
import Paragraph from '@/components/blocks/paragraph';
import Footer from '@/components/ui/footer';

export default {
  name: 'Page',
  components: {
    Heading,
  },
  mixins: [data],
  data() {
    return {
      blockComponents: new Map([
        ['acf/heading', Heading],
        ['acf/posts', Archive],
        ['acf/footer', Footer],
        ['core/columns', Columns],
        ['core/list', Paragraph],
        ['core/paragraph', Paragraph],
      ]),
      archives: 0,
      loadedArchives: 0,
    };
  },
  computed: {
    blocks() {
      const blocks = this.post.gds_blocks;
      blocks.forEach((block) => {
        const name = block.blockName;

        block.component = this.blockComponents.get(name);
      });

      return blocks;
    },
  },
  mounted() {
    this.checkArchives();
  },
  methods: {
    blockData(block) {
      let blockData = '';

      if (block.innerBlocks && block.innerBlocks.length > 0) {
        blockData = block.innerBlocks;
      } else if (block.attrs && block.attrs.length === 0) {
        blockData = block.innerHTML;
      } else blockData = block.attrs.data;

      return blockData;
    },
    checkArchives() {
      const blocks = this.post.gds_blocks;

      const archives = blocks.filter(
        (block) => block.blockName === 'acf/posts' && !block.attrs.data.hide,
      );
      if (archives.length > 1) {
        this.archives = archives.length;
      }
    },
    showArchive(block) {
      if (block.blockName === 'acf/posts') {
        if (this.loadedArchives === this.archives) {
          return true;
        }
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
  .page {
    margin-top: calc(var(--spacer-page) + var(--header-height));
    padding: var(--spacer-s);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: var(--spacer-s);
    row-gap: var(--spacer-page);

    > * {
      grid-column: 1 / -1;
    }

    .page-title {
      grid-column: 1 / -1;
    }

    &--privacy-policy {
      row-gap: initial;
    }

    &--privacy-policy {
      > *:not(.page-title) {
        grid-column: 1 / -1;

        @include mq(m) {
          grid-column: 2 / span 2;
        }
      }
    }
  }
</style>
