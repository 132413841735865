<template>
  <div
    v-if="posts && posts.length > 0 && !data.hide"
    class="archive"
  >
    <h2 class="typo--h2 t-uppercase">
      {{ data.custom_title_active ? data.custom_title : postType }}
    </h2>
    <div class="posts">
      <Thumb
        v-for="(post, index) in posts"
        :key="index"
        :data="post"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Thumb from '@/components/thumb';

export default {
  name: 'Archive',
  components: {
    Thumb,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      request: {
        type: this.data.post_type,
        params: {
          lang: this.$store.state.lang,
          per_page: 100,
        },
      },
      posts: [],
    };
  },
  computed: {
    ...mapGetters(['postTypes']),
    postType() {
      return this.postTypes[this.data.post_type].label;
    },
  },
  mounted() {
    if (!this.data.hide) {
      this.loadPosts();
    }
  },
  methods: {
    async loadPosts() {
      const items = await this.$store.dispatch('getItems', this.request);
      this.posts = items;
      this.$emit('archive-ready');
    },
  },
};
</script>

<style lang="scss">
  .archive {
    display: grid;
    row-gap: var(--row-gap-xs);

    .posts {
      display: grid;
      row-gap: var(--row-gap-xs);

      @include mq(m) {
        row-gap: var(--row-gap-xs);
      }

      @include mq(l) {
        row-gap: 0.8vw;
      }
    }
  }
</style>
