<template>
  <div
    class="thumb"
  >
    <router-link
      class="title typo--h1"
      :to="data.link"
    >
      <h1
        @mouseenter="setTextColor(data.acf?.main_content_text_color)"
        @mouseleave="setTextColor('black')"
        v-html="data.title.rendered"
      />
    </router-link>
    <video
      v-if="
        $route.name === 'Home' &&
          data.acf?.main_content_active &&
          data.acf?.main_content_type === 'video' &&
          data.acf?.main_content_video?.url
      "
      class="background-video"
      autoplay
      muted
      loop
      playsinline
    >
      <source
        :src="data.acf?.main_content_video.url"
        type="video/mp4"
      >
    </video>
    <div
      v-else-if="$route.name === 'Home'"
      class="background-image"
      :style="
        data.acf?.main_content_image && data.acf?.main_content_image?.url
          ? { backgroundImage: `url(${data.acf.main_content_image.url})` }
          : { backgroundColor: 'var(--black)' }
      "
    />

    <router-link
      v-if="$mq.isMobile && data.acf?.main_content_active"
      :to="data.link"
    >
      <img
        v-if="data.acf?.main_content_image && data.acf?.main_content_image?.url"
        :src="data.acf?.main_content_image?.url"
        class="thumb-image"
      >
      <video
        v-else-if="data.acf?.main_content_type === 'video' && data.acf?.main_content_video?.url"
        class="thumb-video"
        autoplay
        muted
        loop
        playsinline
      >
        <source
          :src="data.acf?.main_content_video.url"
          type="video/mp4"
        >
      </video>
    </router-link>
    <div
      v-if="
        data.acf.external_links &&
          data.acf.external_links.length > 0 &&
          !$mq.isMobile
      "
      class="typo--link external-links"
    >
      <Cta
        v-for="(item, externalIndex) in data.acf.external_links"
        v-show="item.link.url !== ''"
        :key="externalIndex"
        :data="{
          url: item.link?.url || '/',
          target: item.link.target,
          title: item.link.title,
        }"
        theme="link"
      />
    </div>
    <div
      v-if="
        data.acf.press_links && data.acf.press_links.length > 0 && !$mq.isMobile
      "
      class="typo--link press-links"
    >
      <Cta
        v-for="(item, pressIndex) in data.acf.press_links"
        v-show="item.link.url !== ''"
        :key="pressIndex"
        :data="{
          url: item.link?.url || '/',
          target: item.link.target,
          title: item.link.title,
        }"
        theme="link"
      />
    </div>
  </div>
</template>

<script>
import Cta from '@/components/typo/cta.vue';

export default {
  name: 'Thumb',
  components: {
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentImage: null,
    };
  },
  methods: {
    setTextColor(color) {
      if (!this.$mq.isMobile) {
        const newColor = color || 'white';
        const root = document.querySelector(':root');
        root.style.setProperty('--text-color', `var(--${newColor})`);
      }
    },
  },
};
</script>

<style lang="scss">
.thumb {
  --thumb-columns: 8;

  display: grid;
  row-gap: var(--row-gap-xxs);
  column-gap: var(--column-gap-s);
  grid-template-columns: repeat(var(--thumb-columns), minmax(0, 1fr));

  @include mq(m) {
    --thumb-columns: 12;
    row-gap: var(--row-gap-xs);
  }

  @include mq(l) {
    row-gap: 0.8vw;
  }

  > * {
    grid-column: 1 / -1;
  }

  .title {
    grid-column-start: 2;
    max-width: max-content;

    h1 {
      text-indent: calc(
        -1 * (
            (
                100vw - var(--scrollbar-width) - (var(--spacer-s) * 2) -
                  (var(--column-gap-s) * (var(--thumb-columns) - 1))
              ) / (var(--thumb-columns))
          ) - var(--column-gap-s)
      );
    }
  }

  .external-links {
    justify-self: start;
    z-index: 1;

    > * {
      max-width: max-content;
    }
  }

  .press-links {
    grid-column-start: 2;
    justify-self: start;
    z-index: 1;

    > * {
      max-width: max-content;
    }
  }

  .thumb-image, .thumb-video {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .background-video {
    display: none;
    top: 0;
    left: 0;
    height: 100dvh;
    width: 100vw;
    position: fixed;
    z-index: -1;
    object-fit: cover;
  }

  .background-image {
    display: none;
    @include mq(m) {
      display: none;
      top: 0;
      left: 0;
      height: 100dvh;
      width: 100vw;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: fixed;
      z-index: -1;
    }
  }

  .title {
    @media (hover: hover) {
      @include mq(m) {
        &:hover {
          & ~ .background-video {
            display: block;
          }
          & ~ .background-image {
            display: block;
          }
        }
      }
    }
  }
}
</style>
