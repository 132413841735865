import createAuthRefreshInterceptor from 'axios-auth-refresh';
import * as env from 'env-var';

import sessionHandler from '@/assets/js/sessionHandler';

export const recoveryPassword = async (body) => sessionHandler.axiosInstance.post('gds/v1/wc/recovery-password/', body);

// export const refreshNonce = async () => {
//   try {
//     const { data } = await sessionHandler.axiosInstance.get('gds/v1/wc/auth/refresh-nonce/');
//     // eslint-disable-next-line no-undef
//     __VUE_WORDPRESS__.state.session.nonce = data.nonce;
//   } catch (e) {
//     console.warn('Error in refreshing wp nonce');
//     console.log(e);
//   }
// };

export default function useAuthorizationAndRetryMiddleware(axiosInstance) {
  axiosInstance.interceptors.request.use((config) => {
    if (sessionHandler.accessToken) {
      config.headers.Authorization = `Bearer ${sessionHandler.accessToken}`;
    }
    return config;
  }, (error) => Promise.reject(error));

  createAuthRefreshInterceptor(
    axiosInstance,
    (failedRequest) => {
      console.warn('Failed request that will be retried', failedRequest);

      return new Promise((resolve) => {
        const retryAfterMs = env.get('VUE_APP_RETRY_INTERVAL_FOR_401').default(750).asIntPositive();
        setTimeout(() => {
          console.log(`'Re-trying after ${retryAfterMs}ms'`);
          resolve(true);
        }, retryAfterMs);
      });
    },
  );
}
