export default {
  SET_AUTH_LOADING(state, value) {
    if (value) {
      document.querySelector('html').classList.add('wc-auth-loading');
    } else {
      document.querySelector('html').classList.remove('wc-auth-loading');
    }
    state.authLoading = value;
  },
};
