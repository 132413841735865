<template>
  <h1>
    {{ data.text }}
  </h1>
</template>

<script>
export default {
  name: 'TitleSecondPart',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>

</style>
